<template>
  <div class="container">
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col cols="12" md="7" class="mx-md-auto">
          <div class="container ml-md-5">
            <Card borderColor="#e06919" class="mb-5">
              <template v-slot:conteudo>
                <p>
                  Braúnas é uma localidade situada ao sul do subdistrito de
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_paracatu_de_baixo' }"
                    >Paracatu de Baixo</router-link
                  >, pertencente ao distrito de
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_monsenhor' }"
                    >Monsenhor Horta</router-link
                  >, no município de Mariana-MG. Situa-se a aproximadamente 4 km
                  de distância do núcleo urbano de Paracatu de Baixo, a 34 km do
                  centro de Mariana e a 140 km de Belo Horizonte, capital do
                  estado de Minas Gerais. Em área contígua ao reassentamento de
                  Paracatu, a região apresenta relevo montanhoso coberto por
                  mata, extensões de pastagem e plantio de eucalipto.
                </p>
                <p>
                  A região é conformada por sítios e fazendas voltadas para a
                  criação de bovinos, sobretudo leiteiro para a produção de
                  leite. Nas terras são realizadas atividades agropecuárias,
                  cultivando-se roçados, hortas, árvores frutíferas e criados
                  animais como galinhas e porcos, garantindo a soberania
                  alimentar das famílias. O cultivo de eucalipto e produção de
                  carvão vegetal também integra a economia local.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="80%"
            src="../../assets/sobre_territorio_braunas_01.png"
          />
          <p
            style="font-size: 0.6em; text-align: center; width: 80%"
            class="mr-md-2"
          >
            Vista de um galinheiro situado no quintal de um terreno de Braúnas.
            Foto: Acervo de vistoria técnica realizada pela Cáritas, 2020.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="4">
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_braunas_02.png"
          />
        </v-col>
        <v-col cols="12" md="4">
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_braunas_03.png"
          />
        </v-col>

        <p style="font-size: 0.6em; text-align: center; width: 60%;">
          Paisagens de Braúnas com relevo montanhoso, coberto por mata nativa,
          extensões de pastagem e plantio de eucalipto. Fotos: Acervo de
          vistoria técnica realizada pela Cáritas, 2020.
        </p>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="3" class="d-flex flex-column">
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_braunas_04.png"
          />
          <p style="font-size: 0.6em; text-align: center;">
            Cultivo de milho em primeiro plano e plantio de eucalipto aos
            fundos. Foto: Acervo de vistoria técnica realizada pela Cáritas,
            2020.
          </p>
        </v-col>
        <v-col cols="12" md="3" class="d-flex flex-column">
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_braunas_05.png"
          />
          <p style="font-size: 0.6em; text-align: center;">
            Vista de árvores frutíferas situadas no quintal de um terreno em
            Braúnas. Foto: Acervo de vistoria técnica realizada pela Cáritas,
            2020.
          </p>
        </v-col>
        <v-col cols="12" md="3" class="d-flex flex-column">
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_braunas_06.png"
          />
          <p style="font-size: 0.6em; text-align: center;">
            Vista de residência típica da comunidade, implantada em área de
            planície. Foto: Acervo de vistoria técnica realizada pela Cáritas,
            2020.
          </p>
        </v-col>
        <v-col cols="12" md="3" class="d-flex flex-column">
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_braunas_07.png"
          />
          <p style="font-size: 0.6em; text-align: center;">
            Vista da casa e do quintal de um terreno em Braúnas, com pomar e
            galinheiro. Foto: Acervo de vistoria técnica realizada pela Cáritas,
            2020.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col cols="12" md="7" class="mx-md-auto">
          <div class="container ml-md-5">
            <Card borderColor="#e06919" class="mb-5">
              <template v-slot:conteudo>
                <p>
                  As casas seguem o padrão das construções típicas rurais
                  mineiras, com volumetria prismática (bloco com lados
                  paralelos), telhados com cobertura cerâmica dispostos em duas
                  ou quatro águas, vãos de portas e janelas fechados em sua
                  maioria com folhas cegas de madeira, havendo também esquadrias
                  metálicas. Geralmente estão implantadas na área plana, assim
                  como as edificações de uso rural. Os cultivos ocupam as
                  extensões de baixada, próximos a corpos hídricos.
                </p>
                <p>
                  Os currais destacam-se entre as construções acessórias de uso
                  rural, relacionados à atividade de criação de bovinos. Os
                  galinheiros, chiqueiros, paióis e coberta de lenha também são
                  comuns nos terrenos, situando-se próximos à residência,
                  geralmente no quintal. As construções utilizam materiais
                  coletados na região como madeira, bambu e barro. Possuem
                  aspecto rústico, geralmente com planta retangular e telhado de
                  uma ou duas águas. Vários mantiveram o uso de telhas cerâmicas
                  e parte foi adotando as telhas de fibrocimento. Para a
                  produção de carvão, nos terrenos encontram-se fornos de
                  encosta, construídos com tijolos maciços.
                </p>
                <p>
                  Não há no local um núcleo urbanizado com presença de templo
                  religioso, escola, estabelecimentos comerciais, etc. Os
                  moradores de Braúnas frequentavam a comunidade de Paracatu de
                  Baixo, se beneficiando do comércio, havendo ainda entre eles
                  relações de amizade e cooperação. Era comum também o comércio
                  de produtos cultivados e de animais entre as comunidades.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="80%"
            src="../../assets/sobre_territorio_braunas_08.png"
          />
          <p
            style="font-size: 0.6em; text-align: center; width: 80%"
            class="mr-md-2"
          >
            Curral típico da comunidade, com aspecto rústico, destinado à
            criação de bovinos. Foto: Acervo de vistoria técnica realizada pela
            Cáritas, 2020.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Os encontros, socialização e atividades religiosas da população
                ocorrem na Igreja de Santo Antônio, em Paracatu de Baixo.
                Destacam-se entre as manifestações tradicionais a
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_religiosidade' }"
                  >Folia de Reis</router-link
                >, a Festa de Santo Antônio e Festa do Menino Jesus, além de
                outras celebrações tradicionais conforme o
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_religiosidade_calendario' }"
                  >calendário litúrgico</router-link
                >
                da comunidade de Paracatu de Baixo.
              </p>
              <p>
                As cachoeiras e o rio também eram locais de diversão e
                socialização antes do rompimento da barragem, ficando
                comprometidos após o desastre. No Rio Gualaxo do Norte os
                moradores além de se banhar, realizavam atividade de pesca para
                lazer e consumo próprio.
              </p>
              <p style="text-align: center">
                <strong>Autoria: Ana Paula Alves Ferreira<sup>1</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p style="margin-bottom: 0;">
              <sup>1</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
  data() {
    return {};
  },
};
</script>

<style scoped>
section {
  margin-bottom: 3em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  border-radius: 8px;
}

.inside__card p {
  font-size: 0.6em;
}
</style>
